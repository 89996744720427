.title-bar {
    height: 8px;
    background-color: rgb(0, 87, 142);
}

// figure out how to make this global
.bar {
    height: 8px;
    background-color: rgb(0, 87, 142);
    margin-top: 15px;
    margin-bottom: 20px;
}

.text-incol {
    min-width: 300px;
    padding-bottom: 10px;
}
