.product-summary-product-images {
    max-height: 320px;
    max-width: 320px;
    //background: #FFFFFF;
    background: #E0E0E0;
}

.product-summary-product-images-mobile {
    max-height: 257px;
}

.product-shortDesc {
    text-align: left;
}

.product-button {
    margin-top: 200px;
    padding: .5rem 2.5rem;
}