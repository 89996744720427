.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.live-support {
	display:block;
	bottom:0px;
	right:15px;
	position:fixed;
	z-index:1000;
}

/* needed to remove padding from mobile site */
div.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* needed to remove padding from mobile site */
div.row {
  margin-right: 0px;
  margin-left: 0px
}

/* needed to remove padding from mobile site */
div.col-lg-1,div.col-lg-10,div.col-lg-11,div.col-lg-12,div.col-lg-2,div.col-lg-3,div.col-lg-4,div.col-lg-5,div.col-lg-6,div.col-lg-7,div.col-lg-8,div.col-lg-9,
div.col-md-1,div.col-md-10,div.col-md-11,div.col-md-12,div.col-md-2,div.col-md-3,div.col-md-4,div.col-md-5,div.col-md-6,div.col-md-7,div.col-md-8,div.col-md-9,
div.col-sm-1,div.col-sm-10,div.col-sm-11,div.col-sm-12,div.col-sm-2,div.col-sm-3,div.col-sm-4,div.col-sm-5,div.col-sm-6,div.col-sm-7,div.col-sm-8,div.col-sm-9,
div.col-xs-1,div.col-xs-10,div.col-xs-11,div.col-xs-12,div.col-xs-2,div.col-xs-3,div.col-xs-4,div.col-xs-5,div.col-xs-6,div.col-xs-7,div.col-xs-8,div.col-xs-9 {
  padding-left: 0;
  padding-right: 0;
}