.caption {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  font-size: 80px;
  color: white;
  font-weight: bold;
}

.btn-xl {
  padding: 10px 20px;
  font-size: 35px;
  border-radius: 10px;
}

.span-images {
    //max-width: 1320.54px;
    max-height: 504.96px;
}

// .side-by-side-images {
//     //max-width: 402px;
//     max-height: 312px;
//     background-color: #E0E0E0;
// }

.side-by-side-images{
  max-height: 312px;
  background: #E0E0E0;
}

.side-by-side-images img {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.side-by-side-images img:hover {
  opacity: 0.6;
}

.border-top-width {
    border-width: 10px !important;
}

//TODO Figure out why this can't be global
$breakpoint-mobile: 767px;

.carousel-inner {
  width:100%;
  // styles for browsers larger than $breakpoint-mobile
  @media only screen and (min-width: $breakpoint-mobile) {
    height: 504.96px;
  }
  // styles for mobile browsers smaller than 480px;
  @media only screen and (max-device-width: 480px) {
    height: 257px;
 }
}