.product-images {
    max-height: 503px;
}

.grip-images {
  max-height: 200px;
  background: #E0E0E0;
}

.product-images-mobile {
  max-height: 257px;
}

.grip-images-mobile {
  max-height: 100px;
  background: #E0E0E0;
}

.grip-tab {
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
}

//TODO Figure out why this can't be global
$breakpoint-mobile: 767px;

.carousel-inner {
  width:100%;
  // styles for browsers larger than $breakpoint-mobile
  @media only screen and (min-width: $breakpoint-mobile) {
    height: 503px;
  }
  // styles for mobile browsers smaller than 480px;
  @media only screen and (max-device-width: 480px) {
    height: 257px;
 }
}

.table_headers {
  background-color: #00578E;
  color: #FFFFFF;
}

.list-group-item {
  padding: 0rem 0rem;
  border: none;
  text-align: right;
}

.border-left-width {
  border-width: 6px !important;
}

.productDescription {
  text-align: left;
}