// .nav-link {
//     color: #000000 !important
// }

.dropdown-menu {
    right: 0;
    left: auto;
}

.logo-icons {
    width: 74px;
    height: 74px;
}

.tiny-phone-icon {
    width: 29px;
    height: 29px;
}