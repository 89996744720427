.text-inline {
    min-width:200px;
}

.video-inline {
    min-width:300px;
    padding-bottom: 15px;
}

.bar {
    height: 1px;
    background-color: black;
}